import {toast, Toaster} from "react-hot-toast";
import React from "react";

const sendAlert = (message, error) => {
    return toast.custom((t) => (
        <div
            className={`${
                t.visible ? 'animate-enter' : 'animate-leave'
            } box_alert ${error ? 'error' : 'sucess'}`}
        >
            {message}
        </div>
    ))
}

const ToastBody = () => {
    return (
        <div className={"alert"}>
            <Toaster position="bottom-right"/>
        </div>
    );
}

export {sendAlert, ToastBody};