import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import './assets/main.css';
import './assets/flexboxgrid.min.css';
import logo from './assets/logo.svg'
import background from './assets/bg-banner.jpg';
import {FiChevronDown} from "react-icons/fi";
import React, {useRef, useState} from "react";
import {sendAlert, ToastBody} from "./util/Alert";
import emailjs from 'emailjs-com';
import InputMask from 'react-input-mask';

function App() {

    const form = useRef();
    const [name, setName] = useState('');
    const [business, setBusiness] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [send, setSend] = useState('Enviar');

    const handleSubmit = (e) => {
        e.preventDefault();

        if (name === "") {
            return sendAlert("Oops! você deve informar o seu nome.", true);
        }
        if (phone === "") {
            return sendAlert("Oops! você deve informar um telefone para contato.", true);
        }
        if (email === "") {
            return sendAlert("Oops! você deve informar um e-mail para contato.", true);
        }


        if (phone.length === 15 || phone.length === 14) {
            setSend("Enviando...");
            emailjs.sendForm('service_2s90pa9', 'template_sjjr3im', form.current, 'user_OLGXjIbt0HucrttIREOEu')
                .then(() => {
                    sendAlert("Obrigado! Já recebemos os dados e logo entraremos em contato.", false);
                    resetFields();
                }, (e) => {
                    sendAlert("Ops, Ocorreu um erro ao enviar os dados inseridos. Tente novamente.", true);
                });
            setSend("Enviar");
        } else {
            return sendAlert("Oops! você deve informar um número de telefone válido.", true);
        }
    }

    const resetFields = () => {
        setName("");
        setBusiness("");
        setPhone("");
        setEmail("");
        setMessage("");
    }

    return (
        <SimpleBar id={"body"} autoHide={true} forceVisible={"y"} style={{maxHeight: "100vh"}}>
            <section className={"banner"} style={{backgroundImage: `url(${background})`}}>
                <div className={"info"}>
                    <img className={"logo"} src={logo} alt={"Logotipo da Rodoseg"}/>
                    <p className={"description"}>
                        <b>Em breve...</b><br/>Seu Caminhão Rodando Mais Seguro
                    </p>
                </div>
                <a href={"#contact"} className={"scroll"}>
                    <FiChevronDown/>
                </a>
            </section>

            <section id={"contact"} className={"contact"}>
                <div className={"title"}>
                    <p>Deixe seus dados abaixo em breve</p>
                    <p>entraremos em contato!</p>
                </div>

                <form className={"form"} ref={form} onSubmit={handleSubmit}>
                    <div className={"row background"}>
                        <div className="col-xs-12 col-sm-6 col-dflex">
                            <div className="form__group">
                                <input type="text" name={"name"} value={name} onChange={(e) => setName(e.target.value)}
                                       placeholder="Nome:"/>
                            </div>
                            <div className="form__group">
                                <input type="text" name={"business"} value={business}
                                       onChange={(e) => setBusiness(e.target.value)} placeholder="Empresa: (Opcional)"/>
                            </div>
                            <div className="form__group">
                                <InputMask
                                    mask={phone.length > 14 ? "(99) 99999-9999" : "(99) 9999-99999"}
                                    maskChar=""
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                >
                                    {() => (
                                        <input placeholder={"Telefone:"} name={"phone"} />
                                    )}
                                </InputMask>
                            </div>
                            <div className="form__group">
                                <input type="email" name={"email"} value={email}
                                       onChange={(e) => setEmail(e.target.value)} placeholder="E-mail:"/>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-dflex">
                            <div className="form__group fgrow">
                                <textarea name={"message"} value={message} onChange={(e) => setMessage(e.target.value)}
                                          placeholder="Mensagem: (Opcional)"/>
                            </div>
                        </div>
                    </div>
                    <button type={"submit"} className={"button"}>{send}</button>
                </form>
            </section>

            <footer>
                <img className={"logo"} src={logo} alt={"Logotipo da Rodoseg"}/>
            </footer>

            <ToastBody/>
        </SimpleBar>
    );
}

export default App;
